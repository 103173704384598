<template>
    <div>
        <div class="" v-html="getMessageSystem(message)['message']"/>
        <div v-if="isButtons(getMessageSystem(message)['buttons'])" class="btns mt-3 d-flex gap-3">
            <button class="btn btn-secondary" v-for="button of getMessageSystem(message)['buttons']" @click="$emit('click', button.handler)">
                {{ button.text }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    methods: {
        isButtons(name) {
            try {
                if (name.length) {
                    return true;
                }
            } catch (e) {}
            return false;
        },
        getMessageSystem(item) {
            try {
                return JSON.parse(item.body);
            } catch (e) {
                return item.body;
            }
        }
    },
    name: "SystemMessage"
}
</script>
