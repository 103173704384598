<template>
    <MainLayout :is-show-header="!isMobile">
          <twilio-chat/>
    </MainLayout>
</template>

<script>

import MainLayout from "../layouts/MainLayout";
import {mapMutations} from "vuex";
import TwilioChat from "../components/TwilioChat";

export default {
    name: 'Chat',
    components: {
        MainLayout,
        TwilioChat
    },
    data() {
        return {
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 992;
        },
    }
}
</script>
