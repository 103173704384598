<template>
    <MainLayout :is-show-header="true">
        <div class="page_list_channels">
            <div class="items_chat">
                <div class="title_page mb-3">Messages</div>
                <div class="box_search_i">
                    <input @keyup="keypressSearchChat" type="text" class="search_chat_inp" placeholder="">
                    <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                </div>
            <div v-for="chat in chats_list_filter" @click="goChat(chat.job_image_id)" class="channel_item" :class="{newMessage: chat.lastMessageIsReaded == false}">
                <div class="chat_item">
                    <div class="point_container">
                        <svg v-if="chat.lastMessageIsReaded == false" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="#FF0000"/>
                        </svg>
                    </div>
                    <div class="box_avatar">
                        <img v-if="getAvatarUser(chat)" class="avatar" :src=getAvatarUser(chat) alt="">
                        <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                    </div>
                    <div class="ps-3">
                        <div class=" user_title">
                            {{getUser(chat).first_name}} {{getUser(chat).last_name}}
                            <span class="newMessages" v-if="chat.lastMessageIsReaded == false">
                                {{chat.channel.channelState.lastMessage.index - (chat.channel.channelState.lastConsumedMessageIndex || -1)}}
                                New messages
                            </span>
                            <div class="job_t">{{styleGuideJob(chat)}}</div>
                        </div>
                    </div>
                </div>
                <div class="date_box">
                    <div>{{getDateCreatedChat(chat.created_at)}}</div>
                </div>
                <div class="icon_a">
                    <img src='@/assets/icons/ep_arrow-right.svg' alt="">
                </div>
            </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import moment from 'moment-timezone';
import { mapGetters, mapMutations } from 'vuex';
const Chat = require('twilio-chat');

export default {
    name: 'ChatsList',
    components: {
        MainLayout,
    },
    data() {
        return {
            token: '',
            user: {},
            chats_list: {},
            chats_list_filter: {}
        }
    },
    mounted() {
        if (this.isLogged) {
            this.user = JSON.parse(localStorage.getItem('strobeart_user')) || {};
            this.fetchAccessToken();
            this.chatsList();
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async fetchAccessToken(username, handler = null) {
            try {
                let result = await this.$http.postAuth(`${this.$http.apiUrl()}access_token`, {
                    identity: this.user?.id,
                });
                this.token = result?.data?.data;

                if (handler) {
                    handler(result?.data?.data);
                }
            } catch (e) {
                console.log(error);
            }
        },
        getDateCreatedChat(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        styleGuideJob(chat) {
            return chat?.job_image?.style_guide || '';
        },
        goChat(job_id) {
            this.$router.push({name: 'Chat',params:{id:job_id}}).then();
        },
        getAvatarUser(chat) {
            if (this.user?.type_user=='business') {
                return chat?.user_editor?.avatar?.url || null;
            } else {
                return chat?.user_business?.avatar?.url || null;
            }
        },
        getUser(chat) {
            if (this.user?.type_user=='business') {
                return chat?.user_editor || {};
            } else {
                return chat?.user_business || {};
            }
        },
        keypressSearchChat(event) {
            let search_text = (event.target.value).toUpperCase();
            if (search_text && search_text.length > 1) {
                if (this.user?.type_user=='business') {
                    this.chats_list_filter = this.chats_list.filter(chat => (chat.user_editor.name).toUpperCase().includes(search_text));
                } else {
                    this.chats_list_filter = this.chats_list.filter(chat => (chat.user_business.name).toUpperCase().includes(search_text));
                }
            } else {
                this.chats_list_filter = this.chats_list;
            }
        },
        async chatsList() {
            this.showLoader()
            try {
                const result = await this.$http.getAuth(`${this.$http.apiUrl()}chats-list`);
                this.chats_list = result?.data?.data;
                this.chats_list_filter= result?.data?.data;
                let _this = this;
                if (this.chats_list_filter) {
                    this.chats_list_filter.forEach(function(value, index) {
                        // _this.$set(_this.chats_list_filter[index], 'teee', index)
                        _this.connectMessagingClient(value.channels_name, index);
                    })
                }
            } catch (e) {
                console.log(e);
            }
            this.hideLoader();
        },
        async connectMessagingClient(channel_id, index) {
            let vm = this;
            let chat_client = await Chat.Client.create(this.token);
            chat_client.on("tokenAboutToExpire", async () => {
                vm.refreshToken();
            });
            try {
                let channel = await chat_client.getChannelByUniqueName(channel_id);
                if (channel) {
                    this.$set(this.chats_list_filter[index], 'channel', channel);
                    let lastConsumedMessageIndex = channel?.channelState?.lastMessage?.index;
                    // let editor = {}
                    //
                    // for (let [key, value] of channel.members.entries()) {
                    //     if (value.state.identity == this.user.id) {
                    //         editor = value.state;
                    //     }
                    // }
                    // console.log(`${editor.identity}// ${index}: ${lastConsumedMessageIndex} | ${editor.lastConsumedMessageIndex}`)
                    let lastMessageIsReaded = channel?.channelState.lastConsumedMessageIndex == channel?.channelState?.lastMessage?.index;

                    this.$set(this.chats_list_filter[index], 'lastMessageIsReaded', lastMessageIsReaded);
                }
            } catch (e) {
                console.log('await this.chat_client.getChannelByUniqueName(name); ERR:', e);
            }
        },
        refreshToken() {
            this.fetchAccessToken(this.user?.id, this.setNewToken);
        },
        setNewToken(tokenResponse) {
            this.chat_client.updateToken(tokenResponse.token);
        },
    },
    computed: {
        ...mapGetters([
            'isLogged',
        ]),
    }
}
</script>

<style lang="scss" scoped>
.page_list_channels {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 15px 120px 15px;
}
.box_avatar .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    margin-bottom: 6px;
    object-fit: cover;
}
.channel_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #494949;
    padding: 21px 10px;
    cursor: pointer;

    &.newMessage {
        background-color: #ededed;
    }
}
.items_chat {
    width: 50%;
}
.user_title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #494949;
}
.icon_a {
    display: flex;
    align-items: center;
}
.chat_item {
    display: flex;
    width: 100%;
}
.date_box {
    padding-right: 30px;
    display: flex;
    align-items: end;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #494949;
}
.job_t {
    display: flex;
    padding-top: 5px;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #494949;
}
.box_search_i {
    position: relative;
}
.search_chat_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 41px;
    width: 100%;
    padding-left: 45px;
}
.search_icon {
    position: absolute;
    left: 16px;
    top: 14px;
    cursor: pointer;
}
.title_page {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.newMessages {
    color: red;
    font-weight: 700;
}
.point_container {
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .items_chat {
        width: 100%;
    }
    .page_list_channels {
        padding: 70px 15px 120px 15px;
    }
}
</style>
